import moment from "moment";

export function getSpecificHumanReadableTime(seconds: number): string {
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.hours();
  const minutes = duration.minutes();
  const secs = duration.seconds();
  let result = "";

  if (hours > 0) {
    result += `${hours} hour${hours > 1 ? "s" : ""} `;
  }
  if (minutes > 0 || hours > 0) {
    // Include minutes if there are hours, even if minutes are 0
    result += `${minutes} minute${minutes !== 1 ? "s" : ""} `;
  }
  result += `${secs} second${secs !== 1 ? "s" : ""}`;

  return result.trim();
}
